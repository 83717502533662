import React from 'react';
import '../app/App.css';


const TermsOfService = () => {
  return (<div className="App">
    <a href="/" className="no-style">
      <header className="App-header">
        <img src="/img/logo.svg" alt=""/>
        <h1>Clutry</h1>
      </header>
    </a>
    <div className="container">
      <h1>Användarvillkor för Clutry</h1>
      <p>Senast uppdaterad: 2023-12-29</p>

      <section>
        <h2>Introduktion</h2>
        <p>Dessa användarvillkor ("Villkor") gäller för din användning av [webbplats/app/tjänst] som
          tillhandahålls av Abrov1nc No. 1337 AB ("företaget", "vi", "oss", "vår"). Genom att
          använda vår tjänst godkänner du dessa villkor.</p>
      </section>

      <section>
        <h2>Användning av Tjänsten</h2>
        <p><strong>Kontoregistrering:</strong> För att använda vissa delar av tjänsten kan du behöva
          skapa ett konto.</p>
        <p><strong>Användaransvar:</strong> Du är ansvarig för att skydda ditt konto och för all
          aktivitet som sker under ditt konto.</p>
        <p><strong>Begränsningar:</strong> Olaglig användning av tjänsten är förbjuden. Använd inte
          tjänsten för att sprida skadlig programvara, spam, eller kränkande innehåll.</p>
      </section>

      <section>
        <h2>Immateriella Rättigheter</h2>
        <p><strong>Rättigheter till Tjänsten:</strong> Vi äger alla rättigheter till tjänsten och
          dess innehåll, inklusive upphovsrätt och varumärken.</p>
        <p><strong>Användarnas Innehåll:</strong> Om du skapar, laddar upp eller delar innehåll
          genom vår tjänst, behåller du rättigheterna till ditt innehåll, men ger oss en licens att
          använda det.</p>
      </section>

      <section>
        <h2>Betalning och Prenumeration</h2>
        <p><strong>Betalningsvillkor:</strong> Vår tjänst erbjuds som en prenumerationsbaserad modell med månatlig betalning. Den exakta prissättningen och betalningsvillkoren för de olika prenumerationsplanerna finns specificerade på vår webbplats eller i vår applikation.</p>
        <p><strong>Prenumerationsvillkor:</strong> Prenumerationer förnyas automatiskt varje månad. Du kommer att debiteras automatiskt vid början av varje prenumerationsperiod enligt de priser som anges vid tiden för din prenumeration. Du kan avbryta din prenumeration när som helst före nästa debiteringscykel för att undvika framtida debiteringar.</p>
      </section>

      <section>
        <h2>Ansvarsbegränsning</h2>
        <p><strong>Ansvarsfriskrivning:</strong> Vi tar inget ansvar för skador orsakade av
          användning av vår tjänst.</p>
        <p><strong>Tjänstens Tillgänglighet:</strong> Vi garanterar inte att tjänsten alltid är
          tillgänglig eller felfri.</p>
      </section>

      <section>
        <h2>Ändringar av Villkor</h2>
        <p>Vi förbehåller oss rätten att ändra dessa villkor. Den uppdaterade versionen kommer att
          gälla från och med det datum den publiceras.</p>
      </section>

      <section>
        <h2>Avslutning</h2>
        <p>Vi kan avsluta eller begränsa din tillgång till tjänsten vid överträdelse av dessa
          villkor.</p>
      </section>

      <section>
        <h2>Tvistlösning och Tillämplig Lag</h2>
        <p>Eventuella tvister som uppstår i samband med dessa villkor ska avgöras enligt svensk
          lag.</p>
      </section>

      <section>
        <h2>Kontaktinformation</h2>
        <p>För frågor om dessa villkor, vänligen kontakta oss på [e-postadress].</p>
      </section>
    </div>
    s
    <footer className="App-footer">
      <ul>
        <li><a href="/terms-of-service">Användarvillkor</a></li>
        <li><a href="/privacy-policy">Integritetspolicy</a></li>
        <li><a href="/contact">Kontakta oss</a></li>
      </ul>
    </footer>
  </div>);
};

export default TermsOfService;