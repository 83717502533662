import React, { useState } from 'react';
import '../app/App.css';


function Contact() {

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [showDialog, setShowDialog] = useState(false);

  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    console.log("Email:", email);
    console.log("Message:", message);

    event.preventDefault();
    const googleFormUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSerMTSWgsYrKnKM18ZeCB9XJVoN4BAGXGFQy8Np5Yii7DMkJg/formResponse';

    try {
      const formData = new FormData();
      formData.append('entry.218287865', message);
      formData.append('entry.579833820', email);
      const response = await fetch(googleFormUrl, {
        method: 'POST', body: formData, mode: 'no-cors', // Necessary to prevent CORS errors, but this also means you won't get a response back
      });

      setEmail('');
      setMessage('');
      setShowDialog(true);
      if (response.ok) {
        console.log('Email submitted successfully');
        // Handle success - clear form, show message, etc.
      } else {
        console.log('Submission failed' + response.status);
        // Handle error
      }

    } catch (error) {
      console.error('Error:', error);
    }

    // Here you would typically send the data to a server or email service
  };

  return <div className="App">
        <a href="/" className="no-style">
          <header className="App-header">
            <img src="/img/logo.svg" alt=""/>
            <h1>Clutry</h1>
          </header>
        </a>
        <div className="email-form-container">
          <h2 className="Section-header">Kontakta oss!</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Epost:</label>
              <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Meddelande:</label>
              <textarea
                  id="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
              />
            </div>
            <button type="submit">Send</button>
          </form>
          {showDialog && (<>
            <div className="Overlay"></div>
            <div className="Dialog">
              <p>Du har skickat ett meddelande till oss!</p>
              <button onClick={() => setShowDialog(false)} className="Button">Stäng</button>
            </div>
          </>)}
        </div>
        <footer className="App-footer">
          <ul>
            <li><a href="/terms-of-service">Användarvillkor</a></li>
            <li><a href="/privacy-policy">Integritetspolicy</a></li>
            <li><a href="/contact">Kontakta oss</a></li>
          </ul>
        </footer>
      </div>;
}

export default Contact;