import React, {useState} from 'react';
import './App.css';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  const [email, setEmail] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageClick = ({imageSrc}: { imageSrc: any }) => {
    setSelectedImage(imageSrc);
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [{
      breakpoint: 1024, settings: {
        slidesToShow: 3, slidesToScroll: 3, infinite: false, dots: false
      }
    }, {
      breakpoint: 600, settings: {
        slidesToShow: 2, slidesToScroll: 2, initialSlide: 2
      }
    }, {
      breakpoint: 480, settings: {
        slidesToShow: 1, slidesToScroll: 1
      }
    }]
  };
  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    const googleFormUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSfoyxX_NrK0qBuDFDMZisKTWTYQ5HaZD5tb5FK-o2RjUJYSNg/formResponse';

    try {
      const formData = new FormData();
      formData.append('entry.4301025', email); // Replace 'entry.4301025' with your form's field ID

      const response = await fetch(googleFormUrl, {
        method: 'POST', body: formData, mode: 'no-cors', // Necessary to prevent CORS errors, but this also means you won't get a response back
      });

      setEmail('');
      setShowDialog(true);

      if (response.ok) {
        console.log('Email submitted successfully');
        // Handle success - clear form, show message, etc.
      } else {
        console.log('Submission failed' + response.status);
        // Handle error
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (<div className="App">
        <header className="App-header">
          <img src="/img/logo.svg" alt=""/>
          <h1>Clutry</h1>
        </header>
        <div className="Image-container">
          <div className="Signup-box">
            <h2>Anmäl till nyhetsbrev</h2>
            <form onSubmit={handleSubmit}>
              <input
                  type="email"
                  placeholder="Din epost"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="Email-input"
                  required
              />
              <button type="submit" className="Submit-button">Anmäl</button>
              {showDialog && (<>
                    <div className="Overlay"></div>
                    <div className="Dialog">
                      <p>Du har nu anmält dig för nyhetsbrev</p>
                      <button onClick={() => setShowDialog(false)} className="Button">Stäng</button>
                    </div>
                  </>)}
            </form>
          </div>
        </div>
        <div className="New-section">
          <h2 className="Section-header">Strukturera och organisera ert företags utrustning för bättre effektivitet.</h2>
          <p className="Section-text">Se till att informationen om företagets utrustning är sparad och lättåtkomlig för varje anställd!</p>
          <Slider {...settings}>
            <div onClick={() => handleImageClick({imageSrc: '/img/start.png'})}>
              <img src="/img/start.png" alt="Start"/>
            </div>
            <div onClick={() => handleImageClick({imageSrc: '/img/offices.png'})}>
              <img src="/img/offices.png" alt="Offices"/>
            </div>
            <div onClick={() => handleImageClick({imageSrc: '/img/create.png'})}>
              <img src="/img/create.png" alt="Create entity"/>
            </div>
            <div onClick={() => handleImageClick({imageSrc: '/img/entity.png'})}>
              <img src="/img/entity.png" alt="Entity"/>
            </div>
          </Slider>
          {selectedImage && (<>
                <div className="Overlay" onClick={() => setSelectedImage(null)}></div>
                <div className="Image-modal" onClick={() => setSelectedImage(null)}>
                  <img src={selectedImage} alt="Expanded"/>
                </div>
              </>)}
        </div>
        <footer className="App-footer">
          <ul>
            <li><a href="/terms-of-service">Användarvillkor</a></li>
            <li><a href="/privacy-policy">Integritetspolicy</a></li>
            <li><a href="/contact">Kontakta oss</a></li>
          </ul>
        </footer>
      </div>);
}

export default App;
