import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './app/App';
import PrivacyPolicy from './policies/PrivacyPolicy';
import TermsOfService from './policies/TermsOfService';
import Contact from './contact/Contact';

const AppRoute = () => {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
  );
};

export default AppRoute;
