import React from 'react';
import '../app/App.css';

const PrivacyPolicy = () => {
  return (<div className="App">
        <a href="/" className="no-style">
          <header className="App-header">
            <img src="/img/logo.svg" alt=""/>
            <h1>Clutry</h1>
          </header>
        </a>
        <div className="container">
          <h1>Integritetspolicy för Clutry</h1>
          <p>Senast uppdaterad: 2023-12-29</p>

          <section>
            <h2>Introduktion</h2>
            <p>Välkommen till Clutry. Denna integritetspolicy förklarar hur vi samlar in, använder, och skyddar personuppgifter när du använder vår webbplats och tjänster.</p>
          </section>

          <section>
            <h2>Insamling av Personuppgifter</h2>
            <p><strong>Personuppgifter som samlas in:</strong> Detta kan inkludera namn, e-postadress, kontaktinformation, och annan information som du frivilligt tillhandahåller när du registrerar dig för tjänsten, använder våra funktioner eller kommunicerar med oss.</p>
            <p><strong>Användningsdata och Cookies:</strong> Vi samlar även in information om hur våra tjänster används. Detta kan inkludera loggdata, IP-adresser och information samlad via cookies och liknande spårningstekniker.</p>
          </section>

          <section>
            <h2>Användning av Personuppgifter</h2>
            <p>Dina personuppgifter används för att:</p>
            <ul>
              <li>Tillhandahålla och förbättra våra tjänster.</li>
              <li>Kommunicera med dig om din användning av tjänsten.</li>
              <li>Uppfylla våra juridiska och regulatoriska skyldigheter.</li>
            </ul>
          </section>

          <section>
            <h2>Delning av Personuppgifter</h2>
            <p>Vi kan dela dina uppgifter med:</p>
            <ul>
              <li>Tjänsteleverantörer och partners som hjälper oss med våra tjänsters drift.</li>
              <li>Om det krävs enligt lag, till exempel för juridiska processer eller för att följa regulatoriska förfrågningar.</li>
            </ul>
          </section>

          <section>
            <h2>Dina Rättigheter</h2>
            <p>Enligt GDPR har du rättigheter rörande dina personuppgifter, inklusive:</p>
            <ul>
              <li>Rätten att begära tillgång till, och rättelse eller radering av dina personuppgifter.</li>
              <li>Rätten att motsätta dig viss behandling och begära begränsning av behandlingen.</li>
              <li>Rätten till dataportabilitet.</li>
            </ul>
          </section>

          <section>
            <h2>Dataskydd</h2>
            <p>Vi vidtar lämpliga säkerhetsåtgärder för att skydda dina personuppgifter mot obehörig åtkomst, ändring, avslöjande eller förstörelse.</p>
          </section>

          <section>
            <h2>Internationella Överföringar</h2>
            <p>Vi kan överföra och behandla dina personuppgifter utanför ditt hemland, inklusive i länder utanför Europeiska ekonomiska samarbetsområdet (EES), där dataskyddslagarna kan skilja sig.</p>
          </section>

          <section>
            <h2>Ändringar i Integritetspolicyn</h2>
            <p>Denna policy kan uppdateras från tid till annan. Vi uppmuntrar dig att regelbundet granska denna sida för de senaste informationen om våra integritetspraxis.</p>
          </section>

          <section>
            <h2>Kontaktinformation</h2>
            <p>Om du har frågor om denna integritetspolicy, vänligen kontakta oss på <a href="/contact">kontakt sidan.</a></p>
          </section>
        </div>
        <footer className="App-footer">
          <ul>
            <li><a href="/terms-of-service">Användarvillkor</a></li>
            <li><a href="/privacy-policy">Integritetspolicy</a></li>
            <li><a href="/contact">Kontakta oss</a></li>
          </ul>
        </footer>
      </div>
  );
};

export default PrivacyPolicy;